/* this is the style for the container inside the app.js */
/* you can remove it and just use the card CSS */
/*.container {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(75, 53, 118);
    background: linear-gradient(
      219deg,
      rgba(75, 53, 118, 1) 33%,
      rgba(248, 177, 60, 1) 100%
    );
      }
  /* ======================================================== */

  .CardGroup {

    display: flex;
    align-items: center;
    justify-content: center;

    
    
  }








  .card {
    transform-style: preserve-3d;
    position: relative;
    padding: 10px;
    width: 1345px;
    height: 250px;
    border-radius: 150px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    align-items: left;
    justify-content: center;
    background: linear-gradient(
      25deg,
      rgb(92, 24, 105) 15%,
      rgb(7, 92, 71) 86%
    );
  }

  .button1 {
    background-color: #04AA6D; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
  }